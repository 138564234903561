import React from "react"
import { Link } from "gatsby"
// import { MagicMotion } from "framer-motion"
import KopfweltAi from "../images/kopfwelt-ai.svg"
import KAi from "../images/k-ai.svg"
import Footer from "../components/footer"
// import {motion} from 'framer-motion'

// import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <Link className="shadow-none" to={`/`}>
          <img src={KopfweltAi} alt="Kopfwelt AI" />
        </Link>
      )
    } else {
      header = (
        <Link className="shadow-none" to={`/`}>
          <img src={KAi} alt="Kopfwelt AI" />
        </Link>
      )
    }
    return (
      <div className="grid grid-cols-8 gap-6 py-10 px-7">
        <header
          className={
            location.pathname === rootPath
              ? "col-span-8 sm:col-span-6 sm:col-start-2"
              : "col-span-8 col-start-1 fixed z-30"
          }
        >
          {header}
        </header>
        <main className="col-span-8 col-start-1">
          {children}
          {/*
          <MagicMotion>
          </MagicMotion>
          */}
        </main>
        <Footer />
      </div>
    )
  }
}

export default Layout
