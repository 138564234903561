import React from "react"
import { a } from "gatsby"

const Footer = () => {
  return (
    <footer className="col-span-8 sm:col-span-5 sm:col-start-2 mt-6">
      <div className="flex-1 w-full pb-20 md:flex md:justify-between md:items-end sm:pb-4">
        <div className="flex flex-col sm:flex-row">
          <a
            href="https://kopfwelt.com/kontakt/"
            className="py-2 mr-3 text-base md:text-xl lg:text-base hover:underline"
          >
            Contact
          </a>
          <a
            href="https://kopfwelt.com/impressum/"
            className="py-2 mr-3 text-base md:text-xl lg:text-base hover:underline"
          >
            Imprint
          </a>
          <a
            href="https://kopfwelt.com/datenschutz/"
            className="py-2 mr-3 text-base md:text-xl lg:text-base hover:underline"
          >
            Privacy statement
          </a>
        </div>
        <div className="flex flex-col sm:flex-row">
          <a
            href="https://www.instagram.com/kopfwelt_/"
            target="_blank"
            rel="noopener noreferrer"
            className="sm:ml-3 text-base md:text-xl lg:text-base hover:underlinep py-2"
          >
            Instagram
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
